.Games {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 1000px) {
	.Games {
		flex-flow: column wrap;
		align-items: center;
		justify-content: space-between;
	}
}