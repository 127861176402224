.Post {
  box-sizing: border-box;
  padding-top: 80px;
}

.heading {
  font-size: 3rem;
  font-weight: 500;
}

.subHeading {
  font-size: 1.6rem;
  margin: 10px 0;
}

.info {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.avi {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
}

.textInfo {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}

.headerImage {
  width: 100%;
  margin: 20px 0;
  height: 300px;
  object-fit: cover;
}

.body {
  font-size: 1.3rem;
  line-height: 1.6;
}
 
.body p {
  padding: 20px 0;

}

.body a {
  color: purple;

}

.body img {
  max-width: 100%;
  display: block;
  margin: auto;
}

.body table {
  border-collapse: collapse;
}

.body table td {
  border: solid purple 1px;
  padding: 3px;
}

.body pre {
  width: 100%;
  overflow: scroll;
}
