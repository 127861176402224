@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.Loader {
	height: 100%;
	width: 100%;
	position: relative;
}

.spinnerContainer {
	position: absolute;
	left: 50%;
	top: 50%;
	box-sizing: border-box;
	transform: translate(-50%, -50%);
	/* margin: 50px 0; */
	/* margin-left: -50%;
  margin-top: -50%; */
  z-index: 110;
  display: flex;
  align-items: center;

}
.spinner {
  display: inline-block;
  height: 20px;
	width: 20px;
	border-radius: 50%;
	border: solid 1px purple;
	border-top-color: rgba(128, 0, 128, 0.212);
	animation-name: spin;
	animation-duration: 1.5s;
	animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.message {
  font-size: 1rem;
  vertical-align: middle;
  margin-left: 10px;
}
