.fullPanel {
  position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
}

.screenPane {
	overflow: hidden;
	height: 100vh;
}

.panelsContainer {
	position: relative;
	transition: all 1s ease;
	/* top: -100vh */
}

.panelLabel {
  line-height: 1;
	position: absolute;
	right: 20px;
	bottom: 0;
	font-size: 8rem;
  color: rgba(0, 0, 0, 0.2);
  font-weight: bolder;
  text-orientation: upright;
}

@media screen and (max-width: 1000px) {
	.fullPanel {
		height: auto;
		display: block;
	}
	
	.screenPane {
		overflow: auto;
		height: auto;
	}
	
	.panelsContainer {
		position: static;
		transition: all 1s ease;
		/* top: -100vh */
	}
	
	.panelLabel {
		line-height: 1.5;
		position: static;
		font-size: 4rem;
		display: block;
		text-align: center;
	}
	
}


