.Library {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: -30px;
  padding-right: -30px;
}

@media screen and (max-width: 1000px) {
  .Library {
    flex-flow: column wrap;
  }
}
