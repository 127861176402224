::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 0, 128, 0.671);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: purple;
}

.content-container {
  width: 50%;
  margin: auto;
  min-width: 800px;
}

.irregular-border {
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  overflow: hidden;
  border: solid 2px purple;
}
.irregular-border.irregular-border-nocolor {
  border-color: transparent;
}

.fitting-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.more-content {
  font-size: 1.4rem;
  font-weight: lighter;
  /* text-transform: capitalize; */
  cursor: pointer;
  color: purple;
}

.more-content span {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.more-content:hover span {
  opacity: 1;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .content-container {
    margin: auto;
    min-width: 90%;  
  }

  .more-content {
    padding-bottom: 30px;
  }

  .more-content span {
    opacity: 1;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit;
}

img {
  border-style: none;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}