.LibraryThumbnail {
  width: calc(50% - 60px);
  /* margin: 30px; */
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
}

.imageContainer {
  width: 100%;
  height: 300px;
  position: relative;
}

.imageOverlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 100%;
  background-color: rgba(128, 0, 128, 0.3);
  transition: all 300ms ease-out;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}
.imageContainer:hover .imageOverlay {
  left: 0;
}

.imageOverlay img {
  margin-bottom: 20px;
  height: 50px;
}


.title {
  font-size: 2rem;
}

.description {
  font-size: 1rem;
}

@media screen and (max-width: 1000px) {
  .LibraryThumbnail {
    width: 100%;
    height: 300px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    margin: 30px 0; 
  }

  .imageContainer:hover .imageOverlay {
    left: 100%;
  }
  .imageContainer:active .imageOverlay {
    left: 0;
  }
}
