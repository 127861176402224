.postThumbnail {
  display: flex;
  flex-flow: row wrap;
  padding: 10px 20px;
  box-sizing: border-box;
  align-items: center;
  transition: all 200ms ease-out;
  cursor: pointer;
}

.postThumbnail:first-child {
  margin-top: 60px;
}

.postThumbnail:last-child {
  margin-bottom: 40px;
}

.postThumbnail:hover {
  background-color: rgba(128, 0, 128, 0.205);
}

.postImageContainer {
  height: 200px;
  width: 200px;
  max-height: 20vh;
  max-width: 20vh;
}

.postImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.postContent {
  margin-left: 40px;
  width: calc(100% - 240px);
  max-width: calc(100% - 25vh - 40px);
}

.heading {
  font-size: 2rem;
  font-weight: 300;
}

.subheading {
  font-size: 1.2rem;
}

.datePublished {
  margin: 5px 0;
  font-size: 0.8rem;
}


@media screen and (max-width: 1000px) {
  .postThumbnail {
    flex-flow: column wrap;
    padding: 0;
    margin: 20px 0;
    padding: 5px;
    box-sizing: border-box;
    align-items: center;
    transition: all 200ms ease-out;
    cursor: pointer;
    border-radius: 0;
  }

  .postImageContainer {
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 100%;
  }

  .postContent {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .postThumbnail:hover {
    background-color: transparent;
  }

  .postThumbnail:active {
    background-color: rgba(128, 0, 128, 0.205);
  }
}